
import { defineComponent } from "vue";

// import Prism Editor
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-yaml";
import "prismjs/themes/prism-coy.css"; // import syntax highlighting styles

import * as jsonToYaml from "json-to-pretty-yaml";
import TemplateService from "../../api/TemplateService";

export default defineComponent({
  name: "CreateTemplate",
  components: {
    PrismEditor,
  },
  data: () => ({
    id: "",
    templateName: "",
    version: "",
    app: "",
    requiredFields: undefined,
    ingress: undefined,
    service: undefined,
    deployment: undefined,
    job: undefined,
    pvc: undefined,
    mapping: undefined,
    autoGenSecrets: undefined,
    secret: undefined,
    tlsSecret: undefined,
    compatibleWithOptions: [],
    compatibleWith: null,
    lineNumbers: true,
    toggleDialog: false,
  }),
  created() {
    this.getTemplateById(this.$route.params.id);
    this.getTemplates();
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.yaml); //returns html
    },
    async getTemplates() {
      try {
        this.compatibleWithOptions = await TemplateService.getTemplateList();
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Die Templates konnten nicht erfolgreich geladen werden.",
        });
      }
    },
    async getTemplateById(id) {
      this.id = id;
      try {
        const template = await TemplateService.getTemplateById(id);
        const ingressYaml = jsonToYaml.stringify(template.ingress);
        const serviceYaml = jsonToYaml.stringify(template.service);
        const deploymentYaml = jsonToYaml.stringify(template.deployment);
        const pvcYaml = jsonToYaml.stringify(template.pvc);
        const secretYaml = jsonToYaml.stringify(template.secret);
        const mappingYaml = jsonToYaml.stringify(template.mapping);
        const jobYaml = jsonToYaml.stringify(template.job);
        const tlsSecret = jsonToYaml.stringify(template.tlsSecret);

        this.template = template;
        this.templateName = template.name;
        this.app = template.app;
        this.version = template.version;
        this.ingress = ingressYaml;
        this.service = serviceYaml;
        this.deployment = deploymentYaml;
        this.job = jobYaml;
        this.pvc = pvcYaml;
        this.mapping = mappingYaml;
        this.secret = secretYaml;
        this.tlsSecret = tlsSecret;
        this.autoGenSecrets = template.autoGenSecrets.toString();
        this.compatibleWith = template.compatibleWith;
      } catch {
        this.$q.notify({
          type: "negative",
          message: "Das Template konnte nicht erfolgreich geladen werden.",
        });
      }
    },
    back() {
      this.$router.push({ name: "template-overview" });
    },
    async deleteTemplate() {
      try {
        await TemplateService.deleteTemplate(this.id);
        this.$q.notify({
          type: "positive",
          message: "Das Template wurde erfolgreich gelöscht.",
        });
        this.toggleDialog = false;
        this.$router.push({ name: "template-overview" });
      } catch (error) {
        this.toggleDialog = false;
        this.$q.notify({
          type: "negative",
          message: error.data.message,
        });
      }
    },
  },
});
